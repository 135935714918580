import { createBatch, getFirestoreDoc } from '@/firebase/firestore'
import { getUid, setLanguageCode } from '@/firebase/auth'
import { tracePerformance } from '@/firebase/performance'
import { logAnalyticsEvent } from '@/firebase/analytics'
import router from '../router'
import useNotifications from '@/composables/useNotifications'
import { setPageTitle, setPageMetaTags, setPageLang } from '@/helpers/page-meta'

import Locales from '@/libraries/locales'
import ShortLocales from '@/libraries/short.locales'
import localizeFilter from '@/filters/localize.filter'

export default {
  state: {
    appColor: null,
    defaultCurrency: null,
    country: null,
    defaultAccount: {},
    locale: 'en-US',
    darkMode: null,
    settingsShow: {
      changePassword: false,
      deleteUser: false,
      deleteAllSpreads: false,
      deleteAllTagsCollections: false,
      deleteAllMoneyBoxes: false,
      deleteAllCards: false,
      deleteAllBanks: false
    }
  },
  mutations: {
    setAppColor(state, color) {
      if (color) {
        state.appColor = color
      }
    },
    setAppLanguage(state, locale) {
      state.locale = locale
    },
    setDefaultCurrency(state, currencyCode) {
      state.defaultCurrency = currencyCode
    },
    setCountry(state, country) {
      state.country = country
    },
    setDefaultAccount(state, data) {
      state.defaultAccount = data
    },
    setDarkMode(state, val) {
      state.darkMode = val
    },
    setSettingsShowField(state, show) {
      if (show && Object.keys(show).length) {
        for (const field of Object.keys(show)) {
          state.settingsShow[field] = show[field]
        }
      }
    },
    clearSettingsShow(state) {
      state.settingsShow = {
        changePassword: false,
        deleteUser: false,
        deleteAllSpreads: false,
        deleteAllTagsCollections: false,
        deleteAllMoneyBoxes: false,
        deleteAllCards: false,
        deleteAllBanks: false
      }
    },
    clearInfo(state) {
      state.appColor = null
      state.defaultCurrency = null
      state.defaultAccount = {}
      state.darkMode = null
      state.settingsShow = {
        changePassword: false,
        deleteUser: false,
        deleteAllSpreads: false,
        deleteAllTagsCollections: false,
        deleteAllMoneyBoxes: false,
        deleteAllCards: false,
        deleteAllBanks: false
      }
    }
  },
  actions: {
    newAppLanguage({ commit }, { locale, save }) {
      if (!locale) { return }

      const arr = locale.split('-')
      if (arr && arr.length) {
        if (arr.length === 2) {
          arr[0].toLowerCase()
          arr[1].toUpperCase()
          locale = arr.join('-')
        } else if (arr.length === 1) {
          arr[0].toLowerCase()
          if (ShortLocales[arr[0]]) {
            locale = ShortLocales[arr[0]]
          }
        }
      }

      if (!locale || !Locales[locale]) { locale = 'en-US' }

      setLanguageCode(locale)

      commit('setAppLanguage', locale)

      setPageTitle(router.currentRoute.value.meta.title)
      if (router.currentRoute.value.meta.layout === 'public') {
        setPageMetaTags()
      }

      setPageLang(locale)

      if (save) { logAnalyticsEvent('newAppLanguage', { locale }) }
    },
    async fetchUserProfile({ commit, dispatch }) {
      const t = tracePerformance('fetchUserProfile')
      t.start()

      try {
        const profile = await getFirestoreDoc('userProfile', getUid())
        if (profile) {
          if (profile.name) { await commit('setUserName', profile.name) }
          if (profile.userPic) { await commit('setUserPic', profile.userPic) }
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchUserProfile', params: {} })
      } finally {
        t.stop()
      }
    },
    async fetchUserSettings({ dispatch }) {
      const t = tracePerformance('fetchUserSettings')
      t.start()

      try {
        const userSettings = await getFirestoreDoc('userSettings', getUid())
        if (userSettings) {
          dispatch('setUserSettings', userSettings)
          return true
        } else {
          return 'userNotFound'
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchUserSettings', params: {} })
      } finally {
        t.stop()
      }
    },
    async setUserSettings({ commit, dispatch, getters }, userSettings) {
      if (getters.lessonStep) { return }

      if (!userSettings) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      let appColor = getters.appColor || 'green-to-blue'

      if (userSettings.appColor !== undefined) {
        appColor = userSettings.appColor
      }
      commit('setAppColor', appColor)

      if (userSettings.defaultCurrency) {
        commit('setDefaultCurrency', userSettings.defaultCurrency)
      }

      if (userSettings.country) {
        await commit('setCountry', userSettings.country)
        await dispatch('subscribeToNews')
      }

      if (userSettings.locale) {
        await dispatch('unsubscribeNews')
        await dispatch('newAppLanguage', { locale: userSettings.locale })
        await dispatch('subscribeToNews')
      }

      if (userSettings.darkMode) {
        await commit('setDarkMode', userSettings.darkMode)
      } else {
        await commit('setDarkMode', 'auto')
      }
    },
    async fetchDefaultAccount({ dispatch }) {
      const t = tracePerformance('fetchDefaultAccount')
      t.start()

      try {
        const defaultAccount = await getFirestoreDoc('userDefaultAccount', getUid())
        if (defaultAccount) {
          await dispatch('defaultAccountFetched', defaultAccount)
        } else {
          await dispatch('defaultAccountFetched', { accountId: null, cardId: null })
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchDefaultAccount', params: {} })
      } finally {
        t.stop()
      }
    },
    async defaultAccountFetched({ commit, dispatch, getters }, defaultAccount) {
      if (!defaultAccount) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('setDefaultAccount', defaultAccount)

      if (defaultAccount.accountId && !getters.accounts[defaultAccount.accountId]) {
        await dispatch('fetchAccount', defaultAccount.accountId)
      }

      if (defaultAccount.cardId && !getters.cards[defaultAccount.cardId] && getters.getLimitNumber('maxCards')) {
        await dispatch('fetchCard', defaultAccount.cardId)
      }
    },
    async saveAppColor({ commit, dispatch, getters }, color) {
      if (getters.lessonStep) { return }

      if (!getters.canUseLimit('setAppColor', false)) {
        commit('setAppShowField', { payWall: true })
        return false
      }

      if (!color) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (!['green-to-blue', 'blue-to-green', 'green-to-gray', 'blue-to-gray', 'green-to-yellow', 'blue-to-red', 'indigo-to-green', 'indigo-to-red', 'purple-to-red', 'purple-to-pink', 'gray-to-gray'].includes(color)) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('saveAppColor')
      t.start()

      const syncTimestamp = new Date()

      try {
        commit('setProcessing', true)

        const data = { appColor: color }

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userSettings',
            id: getUid(),
            data
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('setUserSettings', data)

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'saveAppColor', params: { color: color } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async saveDefaultCurrency({ commit, dispatch, getters }, currencyCode) {
      if (getters.lessonStep) { return }

      if (!getters.canUseLimit('defaultCurrency', false)) {
        commit('setAppShowField', { payWall: true })
        return false
      }

      if (!currencyCode) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('saveDefaultCurrency')
      t.start()

      const syncTimestamp = new Date()

      try {
        commit('setProcessing', true)

        const data = { defaultCurrency: currencyCode }

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userSettings',
            id: getUid(),
            data
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('setUserSettings', data)

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'saveDefaultCurrency', params: { currencyCode: currencyCode } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async saveNewLocale({ commit, dispatch, getters }, locale) {
      if (getters.lessonStep) { return }

      if (!locale) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('saveNewLocale')
      t.start()

      const syncTimestamp = new Date()

      try {
        commit('setProcessing', true)

        const data = { locale }

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userSettings',
            id: getUid(),
            data
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('setUserSettings', data)

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'saveNewLocale', params: { locale } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async saveDarkMode({ commit, dispatch, getters }, mode) {
      if (!getters.online) { return }
      if (mode === getters.darkMode) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!mode || !['auto', 'light', 'dark'].includes(mode)) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      commit('setDarkMode', mode)

      const t = tracePerformance('saveDarkMode')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        commit('setProcessing', true)

        const data = { darkMode: mode }

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userSettings',
            id: getUid(),
            data
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        logAnalyticsEvent('darkModeSettingsSet', { mode })
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'saveDarkMode', params: { mode } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async saveDefaultAccount({ commit, dispatch, getters }, { accountId, cardId, from }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!getters.getLimitNumber('maxAccounts')
        || (+getters.userStats('accounts') > +getters.getLimitNumber('maxAccounts'))
        || (cardId && (!getters.getLimitNumber('maxCards') || (+getters.userStats('cards') > +getters.getLimitNumber('maxCards'))))
      ) {
        commit('setAppShowField', { payWall: true })
        return
      }

      if (!accountId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('saveDefaultAccount')
      t.start()

      const syncTimestamp = new Date()

      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const data = {
          accountId,
          cardId: null
        }

        if (cardId !== undefined) { data.cardId = cardId }

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'set',
            place: 'userDefaultAccount',
            id: getUid(),
            data
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('defaultAccountFetched', data)
        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        if (from) { logAnalyticsEvent('defaultAccountSet', { from, hasCardId: !!data.cardId }) }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'saveDefaultAccount', params: { accountId, cardId }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async saveUserName({ commit, dispatch, getters }, userName) {
      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (userName) { userName = userName.trim() }
      if (!userName) { userName = null }

      const oldName = getters.userName
      if (oldName === userName) { return true }

      const t = tracePerformance('saveUserName')
      t.start()

      const syncTimestamp = new Date()

      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userProfile',
            id: getUid(),
            data: { name: userName },
            logDataInfo: {
              name: userName,
              oldName: oldName
            }
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await commit('setUserName', userName)

        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        logAnalyticsEvent('userNameSaved')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'saveUserName', params: { userName }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    }
  },
  getters: {
    appColor: s => s.appColor,
    defaultCurrency: s => s.defaultCurrency,
    defaultAccount: s => s.defaultAccount,
    locale: s => s.locale,
    country: s => s.country,
    darkMode: s => s.darkMode,
    settingsShow: s => s.settingsShow
  }
}