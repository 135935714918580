export default {
  state: {
    remoteConfig_country: null,
    remoteConfig_platform: null
  },
  mutations: {
    setRemoteConfigData(state, data) {
      if (data) {
        state.remoteConfig_country = data.remoteConfig_country || null
        state.remoteConfig_platform = data.remoteConfig_platform || null
      }
    }
  },
  getters: {
    remoteConfig_country: s => s.remoteConfig_country,
    remoteConfig_platform: s => s.remoteConfig_platform
  }
}
