import { createBatch, createId, getFirestoreDocs, firestoreIncrement } from '@/firebase/firestore'
import { tracePerformance } from '@/firebase/performance'
import { logAnalyticsEvent } from '@/firebase/analytics'
import { getUid } from '@/firebase/auth'
import useNotifications from '@/composables/useNotifications'
import localizeFilter from '@/filters/localize.filter'
import router from '../router'

export default {
  state: {
    spreads: null,
    spreadsReset: 0,
    spreadsShow: {
      add: false,
      edit: null,
      savedSpreads: false,
      addTagMenu: null
    },
    chosenSpreadId: null,
    canSaveSpread: false
  },
  mutations: {
    setSpread(state, { spreadId, data }) {
      if (!state.spreads) { state.spreads = {} }

      if (spreadId && data) {
        state.spreads[spreadId] = data
      }
    },
    deleteSpreadFromStore(state, spreadId) {
      if (state.spreads && state.spreads[spreadId]) {
        delete state.spreads[spreadId]
      }
    },
    resetSpreads(state) {
      state.spreadsReset = Date.now()
    },
    setSpreadsShowField(state, show) {
      if (show && Object.keys(show).length) {
        for (const field of Object.keys(show)) {
          state.spreadsShow[field] = show[field]
        }
      }
    },
    setChosenSpreadId(state, spreadId) {
      state.chosenSpreadId = spreadId
    },
    setCanSaveSpread(state, value) {
      state.canSaveSpread = value
    },
    clearSpreads(state) {
      state.spreads = null
      state.spreadsReset = 0
    },
    clearSpreadsShow(state) {
      state.spreadsShow = {
        add: false,
        edit: null,
        savedSpreads: false,
        addTagMenu: null
      }
    },
    clearInfo(state) {
      state.spreads = null
      state.spreadsShow = {
        add: false,
        edit: null,
        savedSpreads: false,
        addTagMenu: null
      }
      state.chosenSpreadId = null
    }
  },
  actions: {
    async fetchSpreads({ commit, dispatch, getters }) {
      if (getters.lessonStep) { return }
      if (!getters.canUseLimit('spreads', false)) { return }

      const t = tracePerformance('fetchSpreads')
      t.start()

      try {
        const request = { wheres: [['owner', '==', getUid()]], maxLimit: 5 }

        const spreads = await getFirestoreDocs({
          collectionName: 'spreads',
          ...request
        })

        if (spreads) {
          for (const spread of spreads) {
            await commit('setSpread', { spreadId: spread.id, data: spread.data })
          }
        } else {
          await commit('setSpread', { spreadId: null, data: null })
        }

        commit('resetSpreads')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchSpreads', params: {} })
      } finally {
        t.stop()
      }
    },
    async saveSpread({ commit, dispatch, getters }, data) {
      if (getters.lessonStep) { return }

      if (!data || !data.sum || !data.name || !data.accounts || !data.accounts.length) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (!getters.canUseLimit('spreads', false)) {
        commit('setAppShowField', { payWall: true })
        return
      }

      const t = tracePerformance('saveSpread')
      t.start()

      const syncTimestamp = new Date()

      try {
        commit('setProcessing', true)

        const increaseTotalNumber = data.spreadId ? 0 : 1
        const spreadId = data.spreadId ? data.spreadId : createId('spreads')

        const spreadData = {
          sum: +data.sum,
          name: data.name,
          accounts: [],
          timestamp: syncTimestamp,
          owner: getUid()
        }

        for (const i in data.accounts) {
          const account = data.accounts[i]
          let accountInfo = {}

          accountInfo.accountId = account.accountId
          if (account.cardId && getters.getLimitNumber('maxCards')) { accountInfo.cardId = account.cardId }
          if (account.comment) { accountInfo.comment = account.comment }
          if (account.sum) { accountInfo.sum = account.sum }
          if (account.tags && account.tags.length) {
            accountInfo.tags = account.tags
          }

          spreadData.accounts.push(accountInfo)
        }

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'set',
            place: 'spreads',
            id: spreadId,
            data: spreadData,
            merge: true,
            logAction: data.spreadId ? 'edited' : 'added',
            updateStats: data.spreadId ? 0 : 1,
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('spreadEdited', { spreadId, data: spreadData, increaseTotalNumber })

        return spreadId
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'saveSpread', params: { data } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async spreadEdited({ commit }, { spreadId, data, increaseTotalNumber = 0 }) {
      if (!spreadId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('setSpread', { spreadId, data })

      if (increaseTotalNumber) {
        await commit('increaseTotalNumberOf', { field: 'spreads', number: increaseTotalNumber })
      }

      commit('resetSpreads')
    },
    async deleteSpread({ commit, dispatch, getters }, { spreadId = null }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!spreadId || !getters.spreads || !getters.spreads[spreadId]) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('deleteSpread')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Deleting') + '...', { timeout: null })

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'spreads',
            id: spreadId,
            updateStats: -1
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('spreadDeleted', spreadId)

        toastify.replace(toastId, localizeFilter('Deleted'), 'success')
        logAnalyticsEvent('spreadDeleted')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteSpread', params: { spreadId }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async deleteAllSpreads({ commit, dispatch, getters }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!getters.userStats('spreads')) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('deleteAllSpreads')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Deleting') + '...', { timeout: null })

        const spreadsToDelete = []

        const request = { wheres: [['owner', '==', getUid()]] }

        const spreads = await getFirestoreDocs({
          collectionName: 'spreads',
          ...request
        })

        if (spreads) {
          for (const spread of spreads) {
            if (!spreadsToDelete.includes(spread.id)) { spreadsToDelete.push(spread.id) }
          }
        }

        if (!spreadsToDelete.length) {
          toastify.replace(toastId, localizeFilter('Deleted'), 'success')
          return true
        }

        const batchData = []

        for (const spreadId of spreadsToDelete) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'spreads',
            id: spreadId
          })
        }

        if (spreadsToDelete.length) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userStats',
            id: getUid(),
            data: {
              spreads: firestoreIncrement(spreadsToDelete.length * -1)
            },
            noLogs: true
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        for (const spreadId of spreadsToDelete) {
          await dispatch('spreadDeleted', spreadId)
        }

        toastify.replace(toastId, localizeFilter('Deleted'), 'success')
        logAnalyticsEvent('allSpreadsDeleted')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteAllSpreads', params: {}, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async spreadDeleted({ commit }, spreadId) {
      if (!spreadId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('deleteSpreadFromStore', spreadId)
      await commit('increaseTotalNumberOf', { field: 'spreads', number: -1 })
      commit('setChosenSpreadId', null)
      commit('resetSpreads')
    },
    openPutToAccounts({ commit, getters }) {
      if (!getters.canUseLimit('spreads', false)) {
        commit('setAppShowField', { payWall: true })
        return
      }

      router.push({ name: 'PutToAccounts' }).catch(() => { })
    },
    showSavedSpreadsClicked({ commit, getters }) {
      if (!getters.canUseLimit('spreads', false)) {
        commit('setAppShowField', { payWall: true })
        return
      }

      if (!getters.spreads || !Object.keys(getters.spreads).length) {
        const { toastify } = useNotifications()
        toastify.info(localizeFilter('NoSavedSpreads'))
        return
      }

      commit('setSpreadsShowField', { savedSpreads: true })
      logAnalyticsEvent('showSavedSpreadsClicked', { from: 'left-menu' })
    },
    showSaveSpreadsClicked({ commit, getters }, { spreadId, button, from }) {
      if (!getters.canUseLimit('spreads', false)) {
        commit('setAppShowField', { payWall: true })
        return
      }

      if (button === 'add') {
        commit('setSpreadsShowField', { add: true })
      } else if (button === 'edit') {
        commit('setSpreadsShowField', { edit: spreadId })
      }

      if (from) { logAnalyticsEvent('showSaveSpreadsClicked', { from, button }) }
    }
  },
  getters: {
    spreads: s => s.spreads,
    spreadsArr: (s, getters) => {
      if (getters.spreadsReset) {
        //
      }

      let answer = []

      if (s.spreads && Object.keys(s.spreads).length) {
        answer = Object.keys(s.spreads)
      }

      if (answer.length) {
        answer.sort((a, b) => {
          const spreadAName = s.spreads[a].name
          const spreadBName = s.spreads[b].name
          if (spreadAName < spreadBName) { return -1 }
          if (spreadAName > spreadBName) { return 1 }
          return 0
        })
      }

      return answer
    },
    spreadsReset: s => s.spreadsReset,
    spreadsShow: s => s.spreadsShow,
    chosenSpreadId: s => s.chosenSpreadId,
    canSaveSpread: s => s.canSaveSpread
  }
}